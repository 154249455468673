<template>
    <van-cell :title="medium.title" :to="toPath" class="article-item">
        <template #default>
            <my-image :src="medium.thumb_vlist" />
        </template>
        <template #label>
            <van-row type="flex">
                <van-col span="24" class="meta-info">
                    <span>{{ parsedType }}</span>
                    <span class="dot"> · </span>
                    <span>{{ medium.category.name }}</span>
                    <span class="dot"> · </span>
                    <span>{{ medium.author }}</span>
                </van-col>
            </van-row>
        </template>
    </van-cell>
</template>

<script>
import MyImage from '@/components/MyImage'

export default {
    name: 'ListItem',
    props: {
        medium: {
            required: true,
            type: Object
        }
    },
    components: { MyImage },
    computed: {
        toPath () {
            let type = null
            if (this.medium.type === undefined) {
                type = 'reader'
            } else {
                type = this.medium.type
            }

            return `/${type}/${this.medium.id}`
        },
        parsedType () {
            if (this.medium.type === undefined) {
                return '文章'
            }
            if (this.medium.type === 'video') {
                return '视频'
            }
            if (this.medium.type === 'audio') {
                return '音频'
            }
            return ''
        }
    }
};
</script>

<style lang="less">
    .article-item {
        .van-cell__title {
            flex-grow: 3;
        }
        .van-cell__value {
            flex-grow: 1;
            padding-left: 5px;
        }
        .meta-info {
            display: flex;
            align-items: center;
            i {
                margin-right: 5px;
            }
            .dot {
                margin: 0px 5px
            }
        }
    }
</style>
